import blackjackImg1 from "../assets/projects/blackjack/board.png";
import blackjackImg2 from "../assets/projects/blackjack/betting_screen.png";
import blackjackImg3 from "../assets/projects/blackjack/board_example.png";

import upperImg1 from "../assets/projects/upper/logo.svg";
import upperImg2 from '../assets/projects/upper/screenshot1.jpg';
import upperImg3 from '../assets/projects/upper/screenshot2.jpg';
import upperImg4 from '../assets/projects/upper/screenshot3.jpg';
import upperImg5 from '../assets/projects/upper/screenshot4.jpg';

import seImg1 from "../assets/projects/se/logo.png";

import pkImg1 from '../assets/projects/pokedex/mobile.svg';

import teamlyImg1 from '../assets/projects/teamly/screenshot1.png';
import teamlyImg2 from '../assets/projects/teamly/screenshot2.png';
import teamlyImg3 from '../assets/projects/teamly/screenshot3.png';
import teamlyImg4 from '../assets/projects/teamly/screenshot4.png';
import teamlyImg5 from '../assets/projects/teamly/screenshot5.png';
import teamlyLogo from '../assets/projects/teamly/logo.svg';

export const projects = [
    {
        name: "Pokedex",
        description:
            "An app that helps you find information about pokemon. You can search for a pokemon by their id or name.",
        skills: ["React.js", "Redux", "CSS3", "Pokemon API", "Figma"],
        bg: "bg-[#E98080]",
        links: [
            {
                text: "Live",
                url: "https://pokedex.fun",
            },
            {
                text: "Github",
                url: "https://github.com/JordanBlount/pokedex",
            },
        ],
        images: [
            {
                image: pkImg1,
                scale: '0.90',
                objectFit: 'contain',
                className: 'drop-shadow-lg'
            }
        ]
    },
    {
        name: "Blackjack",
        // TODO: Add a note here regarding the betting system
        description:
            "A game of Blackjack where you play against the computer. Feeling lucky? Play to find out. Works on Mobile, Tablet, and Desktop.",
        skills: ["Javascript", "HTML5", "CSS3", "Figma"],
        bg: "bg-green-400",
        links: [
            {
                text: "Live",
                url: "https://blackjack.jordanblount.com/",
            },
            {
                text: "Github",
                url: "https://github.com/JordanBlount/blackjack",
            },
        ],
        images: [
            {
                image: blackjackImg1,
                // scale: "0.85",
                // objectFit: 'contain'
            },
            {
                image: blackjackImg2,
                scale: "1.5",
            },
            {
                image: blackjackImg3,
                scale: "1.15",
            },
        ],
    },
    {
        name: "Teamly",
        description:
            "An organization managament app that allows you to create teams and organize your employees.",
        bg: "bg-[#4C5177]",
        skills: ["React.js", "Spring Boot", "MySQL", "Material UI"],
        links: [
            {
                text: "Github",
                url: "https://github.com/JordanBlount/teamly",
            },
        ],
        images: [
            {
                image: teamlyLogo,
                scale: '0.65',
                objectFit: 'contain'
            },
            {
                image: teamlyImg3,
            },
            {
                image: teamlyImg2,
            },
            {
                image: teamlyImg1,
            },
            {
                image: teamlyImg4,
                scale: '0.975',
                objectFit: 'contain'
            },
            {
                image: teamlyImg5,
                scale: '1.05',
                objectFit: 'contain'
            }
        ]
    },
    {
        name: "Upper Education",
        description:
            "An English school based in Brazil. This website was built for two clients who needed to update their online presence.",
        skills: ["WordPress", "HTML5", "CSS3"],
        bg: "bg-[#161515]",
        links: [
            {
                text: "Visit",
                url: "https://www.uppereducation.com.br",
            },
        ],
        images: [
            {
                image: upperImg1,
                scale: '0.65',
                objectFit: 'contain'
            },
            {
                image: upperImg2,
                scale: '1',
                objectFit: 'contain',
                objectPosition: 'center top'
            },
            {
                image: upperImg3,
            },
            {
                image: upperImg4,
                scale: '1.1',
                objectFit: 'contain',
                objectPosition: 'center top'
            },
            {
                image: upperImg5,
            },
        ]
    },
    {
        name: "Sales Essentials",
        description: "A website built using WordPress for a marketing business based in Atlanta.",
        skills: ["WordPress", "HTML5", "CSS3"],
        bg: "bg-[#056308]",
        links: [
            {
                text: "Visit",
                url: "https://www.salesessentials.org",
            },
        ],
        images: [
            {
                image: seImg1,
                scale: '0.80',
                objectFit: 'contain'
            }
        ]
    },
];

export const email = "jtbemails@gmail.com";
export const hostname = "https://portfolio.jordanblount.com"