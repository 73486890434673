import About from "./sections/About"
import Contact from "./sections/Contact"
import Hero from "./sections/Hero"
import Projects from "./sections/Projects"
import Footer from "./sections/Footer"
import Helmet from "react-helmet"
import { hostname } from "./data"
import { useEffect, useState } from "react"
import { usePrefersColorScheme } from "@anatoliygatt/use-prefers-color-scheme"
import ReactGA from 'react-ga'

const App = () => {

  const preferredColorScheme = usePrefersColorScheme();
  const isDarkMode = preferredColorScheme === 'dark';
  const [isMounted, setIsMounted] = useState(false);


  useEffect(() => {
    setIsMounted(true);
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="app" style={{ visibility: isMounted ? 'visible' : 'hidden' }}>
      <Helmet>
        <meta property="og:url" content={hostname + window.location.pathname + window.location.search} />
        <meta name="twitter:url" content={hostname + window.location.pathname + window.location.search} />
        {isDarkMode ? (
            <link rel="icon" href="/light/favicon.ico" />
        ) : (
            <link rel="icon" href="/favicon.ico" />
        )}
      </Helmet>
      <Hero />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </div>
  )
}

export default App