import { enableBodyScroll } from "body-scroll-lock";
import { disableBodyScroll } from "body-scroll-lock";
import React, { useEffect, useState } from "react";
import { CgMenuRight } from "react-icons/cg";
import ReactGA from 'react-ga';

import "./Navigation.css";

const menu = [
  {
    name: "About Me",
    link: "#about",
  },
  {
    name: "Projects",
    link: "#work",
  },
  {
    name: "Contact",
    link: "#contact",
  },
];

// filter: drop-shadow(0.2px 1.5px 0.1rem rgba(0, 0, 0, 0.2))
// color: #009005

// https://css-tricks.com/finally-it-will-be-easy-to-change-the-color-of-list-bullets/
const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        toggleMenu(false);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = (show) => {
    setMenuOpen(show);

    if (show) {
      disableBodyScroll(document);
    } else {
      enableBodyScroll(document);
    }
  };

  return (
    <nav>
      <ul className="hidden list-none gap-8 md:flex">
        {menu.map((menuItem) => (
          <li key={menuItem.name}>
            <a
              className="hover:text-[#ABDA72] transition ease-in"
              href={menuItem.link}
              aria-label={menuItem.name}
              onClick={() => {
                ReactGA.event({
                  category: "Navigation",
                  action: `Click on ${menuItem.name} on desktop version of site.`,
                  label: `Nav Link`,
                });
              }}
            >
              {menuItem.name}
            </a>
          </li>
        ))}
      </ul>
      <button
        className="md:hidden p-4 translate-x-4"
        onClick={() => toggleMenu(!menuOpen)}
        role="navigation"
        aria-label="Menu principal"
        aria-expanded={menuOpen ? "true" : "false"}
      >
        <CgMenuRight size={34} />
      </button>
      <div
        // I may add "hidden" back to this so it is not hacky
        className={`hardwareA fixed ${
          menuOpen ? "visible opacity-100" : "invisible opacity-0"
        } top-0 left-0 bg-black bg-opacity-30 w-full h-full z-50 transition-opacity duration-300 ease-out`}
        onClick={() => toggleMenu(false)}
        tabIndex="-1"
      ></div>
      <div
        className={`hardwareA fixed top-0 right-0 ${
          menuOpen ? "visible translate-x-0" : "invisible translate-x-[100vh]"
        } bg-gray-800 minW h-full z-50 shadow-md duration-300 ease-out`}
      >
        <div className="relative h-full">
          <button
            className="absolute p-4 top-[3px] -right-1"
            onClick={() => toggleMenu(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-9 w-9"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={1.95}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="flex flex-col items-center pb-10 pt-24 justify-between h-full">
            <ul className="flex flex-col list-none w-full">
              {menu.map((menuItem) => (
                <li key={menuItem.name}>
                  <a
                    className="block py-10 text-center hover:text-[#ABDA72] active:bg-gray-900 transition ease-in"
                    href={menuItem.link}
                    onClick={() => {
                      toggleMenu(false)
                      ReactGA.event({
                        category: "Navigation",
                        action: `Click on ${menuItem.name} on mobile version of site.`,
                        label: `Mobile Nav Link`,
                      });
                    }}
                    aria-label={menuItem.name}
                  >
                    {menuItem.name}
                  </a>
                </li>
              ))}
            </ul>
            <span className="text-sm font-light text-gray-500">v1.0</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
