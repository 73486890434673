import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import './Slide.css';

const Slide = ({ name, images, bg }) => {

  const getScale = (item) => {
    if(item?.scaleX || item?.scaleY) {
      return `scale(${item?.scaleX ? item.scaleX : "1"}, ${item?.scaleY ? item.scaleY : "1"})` 
    } else {
      return `scale(${item?.scale ? item.scale : "1"})`
    }
  }

  const getTranslate = (item) => {
    if(item?.translateX || item?.translateY) {
      return `translate(${item?.translateX ? item.translateX : "0px"}, ${item?.translateY ? item.translateY : "0px"})` 
    } else {
      return `translate(${item?.translate ? item.translate : "0px"})`
    }
  }

  return (
    <div
      className={`shadow-inner relative mx-4 rounded-[0.390rem] h-60 ${
        bg ? bg : "bg-teal-400"
      }`}
    >
      {/* In case there are no available. */}
      {!images && (
        <div className="h-full w-full flex flex-col justify-center items-center text-gray-700 font-medium">
          <p className="text-lg">Photos coming soon!</p>
          <span className="text-3xl opacity-80">🙏🏾</span>
          </div>
      )}
      {images && (
        <Swiper
          spaceBetween={0}
          className="border border-slate-400 h-60 rounded-md"
          navigation={true}
          modules={[Navigation]}
          grabCursor={true}
        >
          {images.map((item, index) => (
            <SwiperSlide>
              <div className="overflow-hidden">
                <img
                  className={`h-60 ${item?.className ? item.className : ''}`}
                  src={item.image}
                  alt={item?.alt ? item.alt : `slide ${index}.`}
                  style={{
                    transform: `${getScale(item)} ${getTranslate(item)}`,
                    objectFit: item?.objectFit ? item.objectFit : "cover",
                    objectPosition: item?.objectPosition ? item.objectPosition : ''
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default Slide;
