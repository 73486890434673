import React from "react";
import { Title, Section, Card, Slide } from "../components";

import { projects } from '../data';

const Projects = () => {
  return (
    <Section id="work" className="flex flex-col items-center px-4 md:px-10">
      <Title className="text-center mx-12">A few things I've worked on</Title>
      {/* h-full was causing problems in Safari */}
      <div className="grid grid-cols-1 auto-rows-fr md:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center max-w-7xl">
        {projects.map((project) => (
          <Card
            title={project.name}
            description={project.description}
            skills={project.skills}
            bg={project.skills}
            links={project.links}
          >
            <Slide name={project.name} bg={project?.bg} images={project?.images} />
          </Card>
        ))}
      </div>
    </Section>
  );
};

export default Projects;
