import React, { useState } from "react";
import { Section, Social } from "../components";
import ReactGA from 'react-ga';

import { email } from "../data";
import { useBeforeunload } from "react-beforeunload";

const Contact = () => {

  const [showEmail, setShowEmail] = useState(false);
  const [hoverCount, setHoverCount] = useState(0);

  useBeforeunload((event) => {
    if(hoverCount > 0) {
      ReactGA.event({
        category: 'Critical Actions',
        action: 'Hover over email button',
        value: hoverCount,
        label: 'Email'
      })
    }
  });

  return (
    <Section
      id="contact"
      // TODO: Old padding -- mx-4 md:mx-10 -- mx-auto max-w-7xl
      className="relative flex flex-col items-center text-center mx-4 md:mx-10 xl:mx-auto xl:max-w-7xl"
    >
      {/* <img className="absolute -top-3 shadow-lg w-24 h-24 object-cover rounded-full border-2 border-black" src={myPhoto}></img> */}
      {/* md:flex md:top-16 lg:top-[74px] */}
      <span className="text-sm absolute flex bg-[#6B7176] px-4  py-1 rounded-md top-8 sm:hidden shadow-lg">
        <span className="block animate-bounce">•</span>
        <span className="block animate-bounce animation-delay-100">•</span>
        <span className="block animate-bounce animation-delay-150">•</span>
      </span>
      <div className="relative flex flex-col items-center text-center">
        {/* sm:mt-6 lg:mt-10 */}
        <p className="sm:mt-6 lg:mt-10 text-sm font-light">Let's talk?</p>
        {/* NOTE: Added shadow to make the text "pop" more. Looks visually pleasing */}
        <h2 className="text-3xl sm:text-[40px] font-bold mt-1 drop-shadow-md">
          Send Me A Message
        </h2>
        <p className="text-base max-w-[240px] sm:max-w-xs leading-tight mt-3">
          Feel free to reach out to me. Even a “Hey!” would be welcome!
        </p>
        <a
          // block py-3 px-8 bg-[#90BD5A] rounded-md font-bold mt-4 mb-10
          className="primary-btn py-3 px-8 mt-4 mb-10"
          href={`mailto:${email}`}
          aria-label="Email"
          onMouseEnter={() => {
            setShowEmail(true);
          }}
          onMouseLeave={() => {
            setShowEmail(false);
            setHoverCount(hoverCount + 1);
          }}
          // Should fix issues on mobile devices
          onClick={() => {
            setShowEmail(false);
            ReactGA.event({
              category: 'Social',
              action: 'Clicked email button',
              label: 'Email'
            })
          }}
        >
          Email
        </a>
        <Social
          container=""
          main="relative flex gap-6"
          tip="text-xs top-8"
          size={24}
          position="contact section"
        />
        {showEmail && (
          <span
            className={`absolute bottom-0 left-1/2 -translate-x-1/2 bg-[#36393b] px-4  py-1 rounded-md shadow-md transition ease-in`}
          >
            {email}
          </span>
        )}
      </div>
      <div className="-z-10 hidden sm:block text-xs lg:text-base">
        <span className="absolute bg-[#6B7176] px-4  py-1 rounded-md top-4 lg:top-4 left-32 shadow-lg opacity-30">
          Hey! I saw the new site.
        </span>
        <span className="absolute bg-[#6B7176] px-4  py-1 rounded-md top-12 lg:top-14 left-4 shadow-lg opacity-60">
          Really?? What did you think?
        </span>
        <span className="absolute bg-[#6B7176] px-4  py-1 rounded-md top-20 lg:top-24 left-32 shadow-lg">
          DUDE. It looks amazing!!
        </span>
      </div>

      <div className="-z-10 hidden sm:block text-xs lg:text-base">
        <span className="absolute bg-[#6B7176] px-4  py-1 rounded-md top-4 lg:top-4 right-32 shadow-lg opacity-30">
          Como está aí?
        </span>
        <span className="absolute bg-[#6B7176] px-4  py-1 rounded-md top-12 lg:top-14 right-4 shadow-lg opacity-60">
          Estou bem! E você?
        </span>
        <span className="absolute bg-[#6B7176] px-4  py-1 rounded-md top-20 lg:top-24 right-32 shadow-lg">
          Tudo tranquilo
        </span>
        {/* <span className="absolute bg-[#6B7176] px-4  py-1 rounded-md top-20 lg:top-[136px] right-4 shadow-lg">
          <span className="animate-bounce">•••</span>
        </span> */}
      </div>
    </Section>
  );
};

export default Contact;
