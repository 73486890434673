import React from 'react'
import './Title.css'

const Title = ({ className, children }) => {
  return (
    //border-b-4 border-[#ABDA72]
    <h2 className={`section-title relative text-2xl md:text-[28px] font-bold mb-8 leading-7 md:leading-tight ${className}`}>{children}</h2>
  )
}

export default Title