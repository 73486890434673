import React from 'react'

const Section = ({ id, className, children }) => {
  return (
    // NOTE: I may change this to pt-20 only to have less white space. The contact section would still need some extra padding above it (e.g. pt-40)
    <section id={id} className={`py-20 ${className}`}>
        {children}
    </section>
  )
}

export default Section