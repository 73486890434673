import React, { useState } from "react";
import { BsGithub, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import { IconContext } from "react-icons";
import ReactGA from "react-ga";

const socialMedia = [
  {
    name: "Github",
    link: "https://github.com/JordanBlount",
    icon: <BsGithub />,
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/jordanblount/",
    icon: <BsLinkedin />,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/jordantblount/",
    icon: <BsInstagram />,
  },
  {
    name: "Twitter",
    link: "https://www.twitter.com/jordantblount/",
    icon: <BsTwitter />,
  },
];

const Social = ({ container, main, tip, size, toolTip = true, position }) => {
  const [toggleTooltip, setToggleTooltip] = useState(false);
  const [tooltipText, setTooltipText] = useState("");

  const toggle = (open) => {
    setToggleTooltip(open);
  };

  const setText = (text) => {
    setTooltipText(text);
  };

  return (
    <div className={container}>
      <div className={main}>
        <IconContext.Provider value={{ size: size }}>
          {socialMedia.map((social) => (
            <ReactGA.OutboundLink
              key={social.name}
              className="transition ease-in hover:text-[#ABDA72]"
              // href={social.link}
              to={social.link}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={social.name}
              eventLabel={`${social.name} (Social - ${position})`}
              onMouseEnter={() => {
                toggle(true);
                setText(social.name);
              }}
              onMouseLeave={() => {
                toggle(false);
                setText("");
              }}
              // Should fix issues on mobile devices
              onClick={() => {
                toggle(false);
                setText("");
              }}
            >
              {social.icon}
            </ReactGA.OutboundLink>
          ))}
        </IconContext.Provider>
        {toolTip && toggleTooltip && (
          <span
            className={`absolute left-1/2 -translate-x-1/2 bg-[#36393b] px-4  py-1 rounded-md shadow-md transition ease-in ${tip}`}
          >
            {tooltipText}
          </span>
        )}
      </div>
    </div>
  );
};

export default Social;
