import React from "react";
import Title from "../components/title/Title";
import Section from "../components/section/Section";

const tech = [
    "Javascript",
    "Git",
    "React.js",
    "Tailwind CSS",
    "Express.js",
    "Spring Boot",
    "MySQL",
    "WordPress",
    "MongoDB",
    "Java",
]

const other = [
  "Trello \n(Project Organization)",
  "Figma \n(For creating all of my designs)"
]

const About = () => {
  return (
    <Section id="about" className="flex flex-col items-center mx-8 md:mx-0">
      <Title className="text-center">About Me</Title>
      <div className="max-w-sm">
        <p className>
          I’m a software engineer who enjoys building wonderful experiences. I
          strive to make applications that are not only beautiful, but highly
          functional.
        </p>
        {/* Check out my <a className="text-[#ABDA72]" href="#">Github</a> to see what
          I'm working on. */}
        <p className="mt-6">Some of the technologies I use:</p>
        <ul className="mt-2 ml-5 grid grid-cols-2 gap-1 list-disc">
            {tech.map(item => (
                <li className="bullet" key={item}>{item}</li>
            ))}
        </ul>
        <p className="mt-6">Others tools:</p>
        <ul className="mt-2 ml-5 grid grid-cols-2 gap-1 list-disc">
            {other.map(item => (
                <li className="bullet whitespace-pre-wrap max-w-[100px] md:max-w-none md:whitespace-normal" key={item}>{item}</li>
            ))}
        </ul>
      </div>
    </Section>
  );
};

export default About;
