import { useState } from "react";
import ReactGA from "react-ga";

const Card = ({ title, description, skills, links, children }) => {

    const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <div
      key={title}
      className="max-w-sm rounded shadow-lg bg-white text-[#495764] w-full pt-4 pb-8 flex flex-col justify-between"
    >
      <div>
        {children}
        <h3 className="mt-4 px-6 text-[28px] font-bold border-l-[6px] border-[#ABDA72]">
          {title}
        </h3>
        <div className="pt-1 px-8">
          <p>{description}</p>
        </div>
      </div>
      <div className="flex flex-col mt-2">
        {/* Tech used */}
        <p className="px-8 text-sm">{skills.join(" • ")}</p>
        {/* Buttons */}
        <div className="flex gap-4 justify-center mt-4 px-8">
          {links.length > 0 &&
            links.map((linkItem) => (
              <ReactGA.OutboundLink
                className="secondary-btn"
                // href={linkItem.url}
                to={linkItem.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={linkItem.text}
                eventLabel={`${linkItem.text} (${title})`}
              >
                {linkItem.text}
              </ReactGA.OutboundLink>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Card;
