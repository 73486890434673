import React, { useState } from 'react'
import Nagivation from '../navigation/Navigation';

import logo from '../../assets/logo.svg'

const Header = () => {

  return (
    <header className='top-0 absolute w-full'>
        <div className="h-20 flex items-center px-4 md:px-8 justify-between">
          {/* Added z-index to logo to stay on top (e.g. when the mobile menu is open.) */}
            <img className="w-[54px] h-[42px] z-[100]" src={logo} alt="Logo" />
            <Nagivation />
        </div>
    </header>
  )
}

export default Header